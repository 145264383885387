import Storage from '@/utils/storage';

// 依据用户标识隔离数据
const USER_ID = sessionStorage.getItem('user_id');

export const JoinFrom = {
  get: function() {
    return Storage.get(`ALL_JOIN_MALL_FROM_INFO_${USER_ID}`) || {};
  },
  set: function(val) {
    let cache = this.get();
    Storage.set(`ALL_JOIN_MALL_FROM_INFO_${USER_ID}`, {...cache, ...val});
  },
}

export const BarndFrom = {
  get: function() {
    return Storage.get(`BRAND_FROM_INFO_${USER_ID}`) || {};
  },
  set: function(val) {
    let cache = this.get();
    Storage.set(`BRAND_FROM_INFO_${USER_ID}`, {...cache, ...val, _already: true});
  },
}