<template>
	<div class="page-step-contact-info">
		<div class="custom-headline">入驻申请</div>
		<el-card>
			<Step v-model="stepActiveIndex"></Step>
			<div class="custom-card">
				<el-form class="contact-info-form" ref="contactInfoForm" :model="contactInfoForm"
					:rules="contactInfoFormRules" label-width="220px">
					<el-row>
						<el-col :span="14">
							<el-form-item label="联系人" prop="coLinkman">
								<el-input v-model.trim="contactInfoForm.coLinkman" placeholder="请输入联系人" maxlength="20"
									clearable></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="14">
							<el-form-item label="手机号" prop="coLinkmanPhone">
								<el-input v-model.trim="contactInfoForm.coLinkmanPhone" placeholder="请输入手机号"
									maxlength="11">
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="11">
							<el-form-item label="验证码" prop="authCode">
								<el-input v-model.trim="contactInfoForm.authCode" placeholder="请输入验证码" maxlength="6">
								</el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-button v-if="sendCodeMessageFlag" class="custom-send-msg-btn">{{remainTime+'s'}}
							</el-button>
							<el-button v-else class="custom-send-msg-btn" @click="sendCodeMessage">
								{{sendCodeMessageErrorFlag ? '重新发送' : '获取验证码'}}</el-button>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="14">
							<el-form-item label="联系人电子邮箱" prop="coLinkmanMail">
								<el-input v-model.trim="contactInfoForm.coLinkmanMail" placeholder="请输入邮箱"
									maxlength="50" clearable>
								</el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="custom-action-box">
					<el-button class="btn reset" size="small" @click="handleReset">重置</el-button>
					<el-button class="btn" size="small" type="primary" :loading="submitPendingFlag"
						@click="jump2nextStep">下一步
					</el-button>
				</div>
			</div>
		</el-card>

	</div>
</template>

<script>
import Step from "../components/Step";
import {
	getJoinAllFormInfo,
	getMobileMessage,
	checkMobileCode,
	checkMobile,
	getMatchUserInfo,
	submitContactInfoForm,
} from "@/api/api-join-mall";
import { JoinFrom } from "@/utils/cache";
export default {
	components: {
		Step,
	},
	data() {
		return {
			stepActiveIndex: [0, 0],
			// 表单
			contactInfoForm: {
				coLinkman: "",
				coLinkmanPhone: "",
				authCode: "",
				coLinkmanMail: "",
			},
			contactInfoFormRules: {
				coLinkman: [
					{
						required: true,
						message: "请输入联系人",
						trigger: "blur",
					},
					{
						pattern: /^[\u4e00-\u9fa5a-zA-Z]+$/,
						message: "仅支持中英文",
						trigger: "blur",
					},
					{ max: 20, message: "最多输入20个字符", trigger: "blur" },
				],
				coLinkmanPhone: [
					{
						required: true,
						message: "请输入手机号",
						trigger: "blur",
					},
					{ max: 11, message: "最多输入11个字符", trigger: "blur" },
					{
						pattern:
							/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/,
						message: "请输入有效的手机号码",
						trigger: ["blur"],
					},
				],
				authCode: [
					{
						required: true,
						message: "请输入验证码",
						trigger: "blur",
					},
					{ max: 6, message: "最多输入6个字符", trigger: "blur" },
					{
						pattern: /^\d{6}$/,
						message: "请输入6位数的验证码",
						trigger: ["blur"],
					},
				],
				coLinkmanMail: [
					{
						required: true,
						message: "请输入邮箱",
						trigger: "blur",
					},
					{
						pattern:
							/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
						message: "请输入正确的邮箱格式",
						trigger: ["blur"],
					},
					{ max: 50, message: "最多输入50位字符", trigger: "change" },
				],
			},
			// 验证码 - 倒计时开关
			sendCodeMessageFlag: false,
			// 验证码 - 防连点
			sendCodeMessageForbidFlag: false,
			// 验证码 - 按钮文字切换
			sendCodeMessageErrorFlag: false,
			remainTime: 59,
			timer: null,
			// 操作
			submitPendingFlag: false,
		};
	},
	methods: {
		/**
		 * 恢复缓存信息
		 */
		recoveryCache(system = {}) {
			JoinFrom.set(system);
			let cache = JoinFrom.get();
			// 判断  && 更新缓存
			if (cache.coLinkman) {
				let temp = {};
				for (let key in this.contactInfoForm) {
					if (typeof cache[key] != "undefined") {
						this.$set(temp, key, cache[key]);
					}
				}
				// 更新表单数据
				Object.assign(this.contactInfoForm, temp);
			}
		},
		async getJoinAllFormInfo() {
			try {
				let res = await getJoinAllFormInfo();
				if (res.data.code === 0) {
					if (res.data.data.submitStatus == 2) {
						this.$router.push({
							name: "StatusSubmitted",
						});
					}
					this.recoveryCache(res.data.data);
				} else {
					this.recoveryCache();
				}
			} catch (error) {
				this.recoveryCache();
			}
		},
		/**
		 * 获取验证码
		 */
		_clearTimer() {
			clearInterval(this.timer);
			this.sendCodeMessageFlag = false;
		},
		sendCodeMessage() {
			if (this.sendCodeMessageFlag || this.sendCodeMessageForbidFlag)
				return;
			this.$refs.contactInfoForm.validateField(
				"coLinkmanPhone",
				(valid) => {
					if (!valid) {
						let { coLinkmanPhone: mobile } = this.contactInfoForm;
						this.sendCodeMessageForbidFlag = true;
						getMobileMessage({ mobile }).then(res => {
							// data == true 发送成功
							if(res.data.data) {
								let timerHandler = () => {
									this.remainTime = 59;
									this.sendCodeMessageFlag = true;
									this.timer = setInterval(() => {
										this.remainTime--;
										if (this.remainTime < 1)
											this._clearTimer();
									}, 1000);
									this.sendCodeMessageErrorFlag = false;
								}
								timerHandler();
							} else {
								this.sendCodeMessageErrorFlag = true;
								this.$message.warning(res.data.message);
							}
							this.sendCodeMessageForbidFlag = false;
						}).catch(() => {
							this.sendCodeMessageForbidFlag = false;
						})
					}
				}
			);
		},
		/**
		 * 重置
		 */
		handleReset() {
			this.$refs.contactInfoForm.resetFields();
			this.sendCodeMessageForbidFlag = false;
			this._clearTimer();
		},
		/**
		 * 下一步
		 */
		jump2nextStep() {
			this.$refs.contactInfoForm.validate((valid) => {
				if (valid) {
					this.submitPendingFlag = true;
					// 判断是否手机号和验证码匹配
					let { coLinkmanPhone: mobile, authCode } = this.contactInfoForm;
					checkMobileCode({ coLinkmanPhone: mobile, authCode }).then(res => {
						if(res.data.data) {
							let saveHandler = () => {
								let contactInfoForm = JSON.parse(
									JSON.stringify(this.contactInfoForm)
								);
								submitContactInfoForm(contactInfoForm)
									.then((res) => {
										if (res.data.code === 0) {
											this.$router.push({ name: "StepCompanyInfo" });
										} else {
											this.$message.warning(res.data.message);
										}
										this.submitPendingFlag = false;
									})
									.catch(() => {
										this.submitPendingFlag = false;
									});
								delete contactInfoForm.authCode;
								JoinFrom.set(contactInfoForm);
							}
							// 判断是否乐活通资质
							// 判断是否拉取资质信息
							checkMobile()
							.then((res) => {
								if (res.data.code === 0) {
									let { status, statusType } = res.data.data;
									if (status == 1) {
										this.submitPendingFlag = false;
										this.$message.warning(
											"此手机号已注册过乐活秀的商户，请更换"
										);
									} else if (status == 2) {
										if(statusType == 1) {
											// 已拉取乐活通资质信息
											saveHandler();
										} else {
											this.$confirm(
												"是否需要乐活通的资质",
												"提示",
												{
													confirmButtonText: "是",
													cancelButtonText: "否",
													showClose: false,
													closeOnClickModal: false,
													closeOnPressEscape: false,
													type: "warning",
												}
											).then(() => {
												getMatchUserInfo({ mobile }).then(res => {
													// data == true 拉取成功
													if(res.data.data) {
														saveHandler();
													} else {
														this.submitPendingFlag = false;
														this.$message.warning('入驻流程未完成无法获取数据');
													}
												}).catch(() => {
													this.submitPendingFlag = false;
													this.$message.warning('系统异常');
												})
											}).catch(() => {
												saveHandler();
											});
										}
									} else {
										saveHandler();
									}
								} else {
									this.submitPendingFlag = false;
									this.$message.warning(res.data.message);
								}
							})
							.catch(() => {
								this.submitPendingFlag = false;
							});
						} else {
							this.submitPendingFlag = false;
							this.$message.warning('验证码错误');
						}
					}).catch(() => {
						this.submitPendingFlag = false;
					})
				}
			});
		},
	},
	created() {
		// 获取全量信息
		this.getJoinAllFormInfo();
	},
	beforeDestroy() {
		this._clearTimer();
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/base.scss";
.page-step-contact-info {
	.contact-info-form {
		margin-top: 50px;
		margin-left: 180px;
	}
}
</style>